import React, { useState,useRef,useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ExampleVideo from '../Example-Video3.webm'

export default function ExampleDialog({ handleCancel }) {
    const [open, setOpen] = useState(true); // Initialize open state
    const videoRef = useRef(null); 
    useEffect(() => {
        if (open) {
            // Add a slight delay to ensure the dialog is fully rendered
            const timeout = setTimeout(() => {
                const videoElement = videoRef.current;
                if (videoElement) {
                    videoElement.play().catch((error) => { 
                    });
                }
            }, 1000); // 100ms delay for video element to load

            return () => clearTimeout(timeout); // Clean up on unmount or open change
        }
    }, [open]);

    // const handleClose = () => {
    //     setOpen(false); // Close the dialog
    //     handleCancel(); // Call the handleCancel function passed as a prop
    // };



    return (
        <>
            <Dialog open={open} onClose={handleCancel} >
                <DialogContent>

                    <DialogContentText>
                        
                        <video
                        ref={videoRef}
                            style={{ width: '80%',height:'80%', marginLeft: "10%" }}
                            controls
                            preload="auto"
                            src={ExampleVideo}
                            alt="Example Video"
                            muted
                            >
                            Your browser does not support the video tag.
                        </video>
                        <Button onClick={handleCancel} className='log-button button0 button2'>

                            סגור
                        </Button>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </>
    );
}
