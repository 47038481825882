// import './App.css';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import HomePage from './components/HomePage';
// import Openers from './components/Openers';
// import OpenersLiked from './components/Favorites';
// import TextLiked from './components/TextedLiked';
// import Navbar from './components/NavBar';
// import React, { useEffect, useState } from 'react';
// import Forum from './components/Forum';
// import DialogModal from './components/Dialogs/DialogLogin';
// import Terms from './components/Terms';
// import Settings from './components/Settings';
// import FirstTimePage from './components/FirstTimePage';
// import MainPage from './components/Bootslander/MainPage';
// import Auth0Callback from './components/Auth0Callback'; // Create this component
// import { Helmet } from 'react-helmet';
// import NotFound from './components/Page404';
// import PurchaseToken from './components/PurchaseToken';
// import ThanksPage from './components/ThanksPage';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import { useAuth0 } from '@auth0/auth0-react';
// import Cookies from 'js-cookie'

// function App() {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
//   const themeColor = '#0A76F0';


//   useEffect(() => {
//     if (isAuthenticated) {
//       const storeTokens = async () => {
//         const token = await getAccessTokenSilently();
//         localStorage.setItem('auth_token', token); // Store token in localStorage
//         Cookies.set('auth_token', token, { expires: 7 }); // Expires in 7 days
//       };
//       storeTokens();
//     }
//   }, [isAuthenticated, getAccessTokenSilently]);


//   useEffect(() => {
//     const checkSession = async () => {
//       try {
//         const token = localStorage.getItem('auth_token');
//         if (token){
//         await getAccessTokenSilently();
//       }} catch (error) {
//         if (error.error === 'login_required') {
//           loginWithRedirect();
//         }
//       }
//     };
  
//     const intervalId = setInterval(checkSession, 5 * 60 * 1000); // Check session every 5 minutes
  
//     return () => clearInterval(intervalId);
//   }, [getAccessTokenSilently, loginWithRedirect]);

//   return (


//     <BrowserRouter>

//       <div className="App">

//         <Helmet>
//           <meta name="theme-color" content={themeColor} />
//           <title>Chatmates</title>

//         </Helmet>
//         <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
//         <Routes>
//           <Route path='/' element={<MainPage />} />
//           <Route path='/login' element={<DialogModal />} />
//           <Route path='/forum' element={<Forum />} />
//           <Route path='/openers' element={<Openers />} />
//           <Route path='/openersliked' element={<OpenersLiked />} />
//           <Route path='/textliked' element={<TextLiked />} />
//           <Route path='/terms' element={<Terms />} />
//           <Route path='/privacy-policy' element={<PrivacyPolicy />} />
//           <Route path='/settings' element={<Settings />} />
//           <Route path='/FirstTime' element={<FirstTimePage />} />
//           <Route path='/Main' element={<HomePage />} />
//           <Route path='/PurchaseToken' element={<PurchaseToken />} />
//           <Route path='/Thanks' element={<ThanksPage />} />
//           <Route path="/auth0_callback" element={<Auth0Callback />} />
//           <Route path="*" element={<NotFound />} /> {/* Catch-all route */}

//         </Routes>
//       </div>
//     </BrowserRouter>

//   );
// }

// export default App;





import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './components/HomePage';
import Openers from './components/Openers';
import OpenersLiked from './components/Favorites';
import TextLiked from './components/TextedLiked';
import Navbar from './components/NavBar';
import React, { useEffect, useState } from 'react';
import Forum from './components/Forum';
import DialogModal from './components/Dialogs/DialogLogin';
import Terms from './components/Terms';
import Settings from './components/Settings';
import FirstTimePage from './components/FirstTimePage';
import MainPage from './components/Bootslander/MainPage';
import Auth0Callback from './components/Auth0Callback'; // Create this component
import { Helmet } from 'react-helmet';
import NotFound from './components/Page404';
import PurchaseToken from './components/PurchaseToken';
import ThanksPage from './components/ThanksPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import { useAuth0 } from '@auth0/auth0-react';
import Turtoial from './components/Turtoial';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const themeColor = '#0A76F0';

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const storeTokens = async () => {
  //       const token = await getAccessTokenSilently();
  //       localStorage.setItem('auth_token', token); // Store token in localStorage
  //     };
  //     storeTokens();
  //   }
  // }, [isAuthenticated, getAccessTokenSilently]);

  // useEffect(() => {
  //   const validateUser = async () => {
  //     if (isAuthenticated) {
  //       try {
  //         const token = await getAccessTokenSilently();
  //         const userInfo = await fetch(`https://${process.env.REACT_APP_AUTO_0_DOMAIN_PRODUCTION}/userinfo`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         });

  //         const userData = await userInfo.json();

  //         if (!userData || userData.sub !== user.sub) {
  //           logout();
  //         }
  //       } catch (error) {
  //         logout();
  //       }
  //     }
  //   };

  //   validateUser();
  //   const intervalId = setInterval(validateUser, 10 * 60 * 1000); // Validate user every 10 minutes

  //   return () => clearInterval(intervalId);
  // }, [isAuthenticated, getAccessTokenSilently, logout, user]);

  useEffect(() => {
    if (window.location.href === 'https://sidekick-israel-frontend.onrender.com') {
      window.location.href('https://chatmates-ai.com');
    }
  }, []);

  useEffect(() => {
    const handleRedirect = () => {
      const currentUrl = window.location.href;
      if (currentUrl.includes('sidekick-israel-frontend')) {
        window.location.replace('https://chatmates-ai.com');
      }
    };

    handleRedirect();
  }, []);



  // const location = useLocation();

  // useEffect(() => {
  //   // Clean up query parameters globally
  //   if (location.pathname === "/Main" && location.search) {
  //     window.history.replaceState({}, document.title, location.pathname);
  //   }
  // }, [location]);

  return (
    <BrowserRouter>
      <div className="App">
        <Helmet>
          <meta name="theme-color" content={themeColor} />
          <title>Chatmates-AI</title>
        </Helmet>
        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/login' element={<DialogModal />} />
          <Route path='/forum' element={<Forum />} />
          <Route path='/openers' element={<Openers />} />
          <Route path='/openersliked' element={<OpenersLiked />} />
          <Route path='/textliked' element={<TextLiked />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/FirstTime' element={<FirstTimePage />} />
          <Route path='/Main' element={<HomePage />} />
          <Route path='/PurchaseToken' element={<PurchaseToken />} />
          <Route path='/Thanks' element={<ThanksPage />} />
          <Route path='/Tutorial' element={<Turtoial/>} />
          <Route path="/auth0_callback" element={<Auth0Callback />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
