import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { BsHearts } from "react-icons/bs";
import { FcGoogle } from 'react-icons/fc';
// import { Microsoft } from "@mui/icons-material";
import  Microsofticon  from "./microsoft.png";

export default function DialogModal({  handleCancel }) {
  const [open, setOpen] = useState(true); // Initialize open state
  const { loginWithRedirect} = useAuth0();
  const [selectedValue, setSelectedValue] = useState('null');
  const url = 'chatmates-ai.com/Main';

  // Embedded browser check function
  const isEmbeddedBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const embeddedPatterns = ['instagram', 'fban', 'fbav', 'fbios', 'facebook'];
    return embeddedPatterns.some(pattern => userAgent.includes(pattern));
  };

  // Force external browser for embedded browsers
  const forceExternalBrowser = (url) => {
    const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
    if (/iphone|ipad|ipod/i.test(navigator.userAgent)) {
      const safariUrl = `x-web-search://?${encodeURIComponent(formattedUrl)}`;
      window.location.href = safariUrl;
    } else if (/android/i.test(navigator.userAgent)) {
      window.location.href = `intent:${formattedUrl}#Intent;scheme=https;package=com.android.chrome;end`;
    } else {
      window.location.href = formattedUrl;
    }
  };

  // Show alert if in embedded browser and prevent repeated alerts
  if (isEmbeddedBrowser() && !sessionStorage.getItem('alertShownInDialogModal')) {
    sessionStorage.setItem('alertShownInDialogModal', 'true');
    alert('שים לב: כדי להבטיח חיבור בטוח לאפליקציה, אנא פתח אותה ישירות בדפדפן האינטרנט שלך.');
    forceExternalBrowser(url);
  }
  const handleGoogleLogin = () => {
    if (isEmbeddedBrowser()) {
      alert('שים לב: כדי להבטיח חיבור בטוח לאפליקציה, אנא פתח אותה ישירות בדפדפן האינטרנט שלך.');
      forceExternalBrowser(url);
    }
    loginWithRedirect({
      authorizationParams: {
        connection: "google-oauth2",
        redirect_uri: "https://chatmates-ai.com/Main", // Ensure this matches your app's URL
        // redirect_uri: "http://localhost:3000/Main", // Ensure this matches your app's URL
        
      },
    });
  };

  const handleMicrosoftLogin = () => {
    if (isEmbeddedBrowser()) {
      alert('שים לב: כדי להבטיח חיבור בטוח לאפליקציה, אנא פתח אותה ישירות בדפדפן האינטרנט שלך.');
      forceExternalBrowser(url);
    }
    loginWithRedirect({
      authorizationParams: {
        connection: "windowslive", // This is the connection name for Microsoft
        // redirect_uri: "http://localhost:3000/Main", // Ensure this matches your app's URL
        redirect_uri: "https://chatmates-ai.com/Main", // Ensure this matches your app's URL
        scope: "openid profile email", // Optional, adjust scopes as needed
      },
    });
  };

  const handleEmailLogin =  () => {
    
    loginWithRedirect({
        connection: "Username-Password-Authentication", // Use your database connection
        // redirect_uri: "http://localhost:3000/Main"
        redirect_uri: "https://chatmates-ai.com/Main", // Ensure this matches your app's URL

      });
      // Redirect to Main after successful login
      // window.location.href = "http://localhost:3000/Main";
    }

  const handleLogin = () => {
    if (isEmbeddedBrowser()) {
      alert('שים לב: כדי להבטיח חיבור בטוח לאפליקציה, אנא פתח אותה ישירות בדפדפן האינטרנט שלך.');
      forceExternalBrowser(url);
    }
    loginWithRedirect({
      returnTo: window.location.origin, // Specify the desired return URL
      // Add a callback to handle the login redirect
      onRedirectCallback: (appState) => {
      localStorage.setItem('isLoggedIn', 'true');
        
      }
    });
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (selectedValue) {
      localStorage.setItem('termsAccepted', true);
      const check = localStorage.getItem('termsAccepted');

    }
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
    handleCancel(); // Call the handleCancel function passed as a prop
  };

  const handleOpen = () => {
    setOpen(true); // Set open state to true
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} style={{fontFamily:'Secular One'}}>
       <DialogTitle style={{textAlign:'center',fontFamily:'Secular One'}}>ברוך הבא</DialogTitle>
        <DialogContent style={{textAlign:'center',fontSize:'x-large'}}>
        
        <BsHearts style={{color:'red'}} />
        הרשמה חינם 
        
        <BsHearts  />

          <DialogContentText style={{padding:'25px',fontFamily:'Secular One'}}>
            
              

              <div className="btn-google">
      <button
        className="log-button button0 button2"
        style={{ padding:'20px',borderRadius:'10px',boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)"  }}
        onClick={handleGoogleLogin}
      >
        <FcGoogle style={{fontSize: '29px' }}/> Sign in with Google
      </button>
    </div>
              <div className="btn-microsoft">
      <button
        className="log-button button0 button2 login_button" 
        
        onClick={handleMicrosoftLogin}
      >
        <img src={Microsofticon} style={{width:'13%'}} /> Sign in with Microsoft
      </button>
    </div>
    <div className="btn-login">
                <button
                  style={{marginTop:'20px',borderRadius:'10px',boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)"}}
                  onClick={handleLogin}>היכנס עם אימייל 
                </button>
              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleConfirm}>Terms</Button> */}
          <Button onClick={handleClose}>סגור</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
